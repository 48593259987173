@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: black;
  height: 100%;
}

::-webkit-scrollbar {
  width: 0;
}

@layer base {
  input:focus {
    outline: none;
  }
}

@layer utilities {
  .bg-glass {
    @apply bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-70 border bg-white/75;
  }

  .shadow-panel {
    box-shadow: 0 20px 80px 5px black;
  }

  .character {
    &:nth-child(3) {
      margin-right: 0.75rem;
    }
  }

  .instagram-png {
    box-shadow: 0px 100px 80px rgba(184, 30, 30, 0.26),
      0px 41.7776px 33.4221px rgba(184, 30, 30, 0.186902),
      0px 22.3363px 17.869px rgba(184, 30, 30, 0.154988),
      0px 12.5216px 10.0172px rgba(184, 30, 30, 0.13),
      0px 6.6501px 5.32008px rgba(184, 30, 30, 0.105012),
      0px 2.76726px 2.21381px rgba(184, 30, 30, 0.0730979);
  }
}

@font-face {
  font-family: "Proxima Regular";
  src: url("../public/fonts/proximanova_regular.ttf");
}

@font-face {
  font-family: "Proxima Light";
  src: url("../public/fonts/proximanova_light.otf");
}

@font-face {
  font-family: "Proxima Bold";
  src: url("../public/fonts/proximanova_bold.otf");
}

@font-face {
  font-family: "Proxima Extrabold";
  src: ("../public/fonts/proximanova_blackit.otf");
}

footer {
  font-family: Proxima Regular;
}

.proxima-regular {
  font-family: "Proxima Regular";
}

.proxima-bold {
  font-family: Proxima Bold;
}

.proxima-extrabold {
  font-family: Proxima Bold;
}

.root {
  background-color: black;
  min-height: 100%;
}

.background-container {
  height: 100vh;
  max-width: 1440px;
  background-image: url("../public/assets/images/background.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 75%;

  // @apply bg-contain
}

//xl:bg-cover 4xl:bg-contain

.container-sign_up {
  background-color: #e4d8d1;
}

.privacy-policy {
  font-family: Proxima Regular;
  color: rgba(0, 0, 0, 0.5);
}

.stepBlock:not(:last-child) > .circleWrapper::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: white;
  margin: auto;
  z-index: -1;
}

.step-line.stepBlock:not(:last-child) > .circleWrapper::after {
  background: -webkit-linear-gradient(180deg, #ffa621, #fe823c, #fe5f1b);
}

.stepBlock:last-child {
  width: 60px;
}

.selected {
  background: -webkit-linear-gradient(180deg, #ffa621, #fe823c, #fe5f1b);
}

.btn {
  font-family: Proxima Regular;
  background: -webkit-linear-gradient(180deg, #ffa621, #fe823c, #fe5f1b);
  @apply w-full py-6 px-8 rounded-[49px] text-[25px] font-semibold text-black drop-shadow-default;

  &-instagram {
    background: -webkit-linear-gradient(
      240deg,
      #fbe18a,
      #fcbb45,
      #f75274,
      #d53692,
      #8f39ce,
      #5b4fe9
    );
    @apply text-white drop-shadow-inst;
  }
}
